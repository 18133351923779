import { defineStore } from "pinia";
import ApiService from "@/core/services/apiServicev3";
import { nextTick } from "vue";

interface RootState {
  current: null | any
}

export const useStocksSearchStore = defineStore({
  id: "stocksSearchStore",
  state: () =>
    ({
      current: null
    } as RootState),
  getters: {
  },
  actions: {
    async initProduct(item: any) {
      const template = {
        id: item.id,
        title: item.title,
        barcode: item.specifications.find(spec => spec.name === 'EAN')?.options ?? null,
        image: item.media.filter(item => item.media_type === 'image').map(item => item.file_full_url)[0],
        stockLocations: {
          totals: {
            quantity: 0,
          },
          locations: item.products_stocks?.map((location) => {
            return {
              id: location.id,
              label: location.location_name,
              status: location.location_status,
              quantity: Number(location.quantity),
              plusQuantity: null,
              type: location.stock_type,
              isActivated: false,
              isLoading: false,
            };
          }) ?? [],
        },
        activeInOrders: await this.salesLines(item.id),
        isLoading: false,
      }  as any

      this.current = template;
  
      this.calculateTotals();
    },
    async salesLines(productId: number) {

      const payload = {
        q: '',
        sort: ['id:desc'],
        matchingStrategy: 'all',
        facets: ['*'],
        filter: [
          [`product_id=${productId}`],
          ['sale_status != completed'],
          ['sale_status != pending'],
          ['sale_status != concept']
        ],
        offset: 0,
        limit: 1000,
      };

      const { data: lines } = await ApiService.post('apiUrlSearch', 'indexes/sales_lines/search', payload);

      const data = [] as any;
      for (const line of lines.hits) {
        const template = {
          productId: line.product_id,
          id: line.sale_id,
          quantity: Number(line.quantity),
          saleStatus: line.sale_status
        }
        data.push(template);
      }

      return data;
    },
    async updateStockLocationQuantity(location, searchInput) {
      const newQuantity = location.quantity + location.plusQuantity;
    
      try {
        this.current.isLoading = true;
        location.isLoading = true;
    
        const payload = {
          quantity: newQuantity
        }
    
        await ApiService.put('apiUrl', `products/stock/${location.id}`, payload);
      }
      finally {
        location.quantity = newQuantity;
        location.isActivated = false;
        location.plusQuantity = null;
        location.isLoading = false;
        this.current.isLoading = false;
        this.calculateTotals();

        nextTick(() => {
          searchInput.focus();
        })
      }
    },
    async calculateTotals() {
      this.current.stockLocations.totals.quantity = this.current.stockLocations.locations?.reduce((total, location) => total + Number(location.quantity), 0)
    }
  }
});